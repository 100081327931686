@use "../config/"as *;

.waf-fixtures {
    @extend %p-half;

    .title {
        @extend %font-24-pr;
    }

    .sub-title {
        @extend %font-18-psb;
        @extend %c-orange-10;
    }

    .waf-body {
        @extend %m-x-full-neg;
    }

    .table {
        &-title {
            @extend %font-20-psb;
            @extend %m-b-two-space;
            @extend %p-x-full;
        }

        &-head {
            @extend %d-none;

            .text {
                @extend %c-white-10;
            }
        }
    }

    .date {
        flex-basis: 100%;
        @extend %w-100;
        @extend %c-blue-bg-10;

        .text {
            @extend %c-white-10;
            @extend %font-16-psb;
        }

    }

    .text {
        @extend %font-14-pr;
    }


}

.download-section {
    @extend %p-x-full;
    .head-title {
        @extend %d-none;
    }
    .list-item {
        gap: var(--one-n-half-space);
        @extend %flex-column;
        @extend %p-one-n-half;
        @extend %half-radius;
        @extend %m-b-one-n-half;
        @include border(1, c-blue-2, 1);
        @include bg(c-light-grey-1, 2);

    }

    .action-title {
        @extend %font-20-psb;
        @extend %c-blue-10;
    }

    .btn-link {
        @extend %c-orange-10;
        @extend %font-16-pb;
        .text {
            @extend %font-16-pb;
        }
        &:after {
            content: '\e809';
            font-size: 2.2rem;
            line-height: 2rem;
            font-family: $font-icon;
        }
    }

    .waf-body {
        @extend %m-x-zero;
    }
}

@media (min-width:$tablet-min-width) {

    .waf-fixtures {
        .waf-body {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
        }
        .waf-head {
            .title {
                font-size: 3.6rem;
            }
            .sub-title {
                font-size: 2rem;
            }
        }
        .table {
            display: flex;

            &-title {
                padding: 0;
            }

            &-head {
                display: flex;
                @include border-radius(var(--half-radius) var(--half-radius) 0 0);

                .date {
                    .text {
                        font-family: $font-pb;
                        @include color(c-white);
                    }
                }

                .text {
                    font: 1.4rem $font-pb;
                }
            }

            &-left {
                width: 20rem;

                .table-head {
                    border-top-right-radius: 0;
                }
            }

            &-right {
                width: calc(100% - 20rem);

                .table-head {
                    border-top-left-radius: 0;
                }
            }
        }

        .date {
            background-color: transparent;

            .text {
                font-family: $font-pr;
                @include color(c-blue);
            }

        }

        .filter-section {
            width: 100%;
        }
        .table-responsive {
            width: 70%;
        }
        .download-section {
            width: 30%;
        }
    }

    .download-section {
        // margin-top: calc(10 * var(--full-space));
        .waf-body {
            display: unset;
        }
    }
    .waf-download {
        .download-section {
            width: 100%;
            padding-inline: 0;
            .action-list {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: var(--full-space);
                // display: flex;
                // flex-wrap: wrap;
                // gap: var(--full-space);
            }
            .list-item {
                // width: calc(33.33% - var(--full-space));
            }
        }
    }
}